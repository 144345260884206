import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import SuggestedSongs from '../../../features/SuggestedSongs/SuggestedSongs'
import { selectUser } from '../../../features/user/userSlice'
import Feed from '../Feed/Feed'
import FeedMusic from '../FeedMusic/FeedMusic'
import SuggestSongButton from '../SuggestSongButton/SuggestSongButton'
import './FeedWrapper.scss'

export default function FeedWrapper () {
  const [component, setComponent] = useState('feed')

  const user = useSelector(selectUser);
  const username = useSelector(state => state.user.username);

  const handleTabChange = (event, comp) => {
    event.preventDefault()
    
    setComponent(comp)
  }
  return (
    <div className="FeedWrapper">
      <div className="row d-flex align-items-center">
        <ul className="tabs mr-auto mb-4">
          <li>
            <a
              href="#"
              onClick={(e) => handleTabChange(e, 'feed')}
              className={component === 'feed' ? "active" : ""}
            >feed</a>
          </li>
          <li>
            <a
              href="#"
              onClick={(e) => handleTabChange(e, 'music')}
              className={component === 'music' ? "active" : ""}
            >music</a>
          </li>
          <li>
            <a
              href="#"
              onClick={(e) => handleTabChange(e, 'suggested')}
              className={component === 'suggested' ? "active" : ""}
            >suggested</a>
          </li>
        </ul>
        <div>
          { user.userData.id && !username.loading && username.set && (
            <SuggestSongButton />
          )}
        </div>
      </div>
      {component === 'feed' ? <Feed /> : undefined}
      {component === 'music' ? <FeedMusic /> : undefined}
      {component === 'suggested' ? <SuggestedSongs /> : undefined}
    </div>
  )
}