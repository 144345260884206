import { Formik } from 'formik'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import MultiStep from '../../components/MultiStep/MultiStep';
import Step2 from './Step2'
import Step3 from './Step3'
import './AddSong.scss'

export default function AddSong () {
  const [ step, setStep ] = useState(1)
  const [ newSong, setNewSong ] = useState({artist: '', song: ''})
  const userData = useSelector(state => state.user.userData)
  const username = useSelector(state => state.user.username.username)

  const multiStepConfig = [{
    icon: 'fas fa-list',
    text: 'Song info'
  },{
    icon: 'fab fa-youtube',
    text: 'Youtube link'
  },{
    icon: 'fas fa-check',
    text: 'All good!'
  }]

  return (
    <div>
      <h3>Suggest a song</h3>
      <MultiStep config={multiStepConfig} step={step}/>
      { step === 1 ? 
        <div>
          { !userData.id || !username ? 
            <div className="alert alert-danger">
              you should log in first
              {/* (!userData.id || !username) */}
            </div>
            : <div></div>
          }
          <Formik
            initialValues={{ artist: newSong.artist, song: newSong.song }}
            validate={values => {
              const errors = {};
              if (!values.artist)
                errors.artist = 'Required';
              else if (values.artist.length < 3) {
                errors.artist = 'Min 3 chars';
              } else if (values.artist.length > 100) {
                errors.artist = 'Max 100 chars';
              }

              if (!values.song)
                errors.song = 'Required';
              else if (values.song.length < 3) {
                errors.song = 'Min 3 chars';
              } else if (values.song.length > 100) {
                errors.song = 'Max 100 chars';
              }

              return errors;
            }}
            onSubmit={(values) => {
              setNewSong(values)
              setStep(2)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row add-song">
                  <div className="col-sm-5">
                    <div className="form-group">
                      <label>Artist</label>
                      <input
                        type="text"
                        name="artist"
                        value={values.artist}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-red error-field">
                        {errors.artist && touched.artist ? 
                          <span>{errors.artist}</span>
                        : <span>&nbsp;</span>}
                      </small>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <div className="form-group">
                      <label>Song name</label>
                      <input
                        type="text"
                        name="song"
                        value={values.song}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-red error-field">
                        {errors.song && touched.song ? 
                          <span>{errors.song}</span>
                        : <span>&nbsp;</span>}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6"></div>
                  <div className="col-6 text-right">
                    <button className="btn btn-danger my-5" type="submit" disabled={isSubmitting}>next step</button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      : <div></div>
      }
      { step === 2 ? 
        <Step2 song={newSong} setStep={(step) => setStep(step)}/>
      : <div></div>
      }
      { step === 3 ? 
        <Step3/>
      : <div></div>
      }
    </div>
  )
}
/*
function Step2 ({song, setStep}) {
  const [ yid, setYid ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const userData = useSelector(state => state.user.userData)
  const username = useSelector(state => state.user.username.username)

  const loadSong = async () => {
    console.log(song);
    const yid = await FirebaseActions.loadYoutubeSong(song.artist + ' - ' + song.song)
    setYid(yid)
  }

  useEffect(() => {
    loadSong()
  }, [])

  const handleSubmit = () => {
    setLoading(true)
    
    if(!userData.id || !username)
      return toast.error('log in and set username')

    const data = {
      original: {
        artist: song.artist,
        song: song.song,
        yid: yid,
      },
      uid: userData.id,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      rev: 1
    }
    firebase.firestore().collection('music').add(data)
    .then(res => {
      setLoading(false)

      FirebaseActions.likeSong(username, userData.id, res.id)
      .then( res => {
        setStep(3)
      })
      .catch(res => {
        toast.error('an error occured')
      })
    })
    .catch(err => {
      alert('an error occured')
    })
  }

  return (
    <div>
      { yid ? 
        <iframe width="100%" height="350" src={"https://www.youtube.com/embed/" + yid + '?autoplay=1'} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        : <div>loading</div>}
      <div className="row">
        <div className="col-6">
          <button className="btn btn-default my-5" type="submit" onClick={() => setStep(1)}>back</button>
        </div>
        <div className="col-6 text-right">
          <button className="btn btn-primary my-5" type="submit" onClick={handleSubmit}>Upload</button>
        </div>
      </div>
    </div>
  )
}

function Step3 ({song, back}) {
  const history = useHistory()
  useEffect(() => {
    setTimeout(() => {
      history.push('/')
    }, 3000)
  }, [])

  return (
    <div className="add-song">
      <div className="text-center">
        <i class="fas fa-thumbs-up fa-10x text-secondary-1"></i><br />
        <p className="mt-5">we'll redirect you to the frontpage in 3 secs</p>
      </div>
    </div>
  )
}
*/