import React, { useEffect, useState } from 'react'
// 
import './About.scss';

const About = () => {
  const [ music, setMusic ] = useState([])
  const [ playing, setPlaying ] = useState(null)
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  let lastPrintedDate = null

  return (
    <div>
      <h3>About</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dignissim et justo sed gravida. Cras vel est quis ipsum iaculis mattis. Suspendisse accumsan, turpis sed ultrices consectetur, mauris magna iaculis enim, sed iaculis est eros in neque. Vivamus eget molestie sapien. Ut accumsan tincidunt vulputate. Pellentesque tristique risus maximus ligula porta, eu rhoncus lectus dignissim. Vestibulum vel interdum odio. Pellentesque lacinia, diam ut facilisis sagittis, arcu elit sollicitudin velit, non maximus quam lorem quis lacus. Fusce tincidunt ante magna, vitae feugiat nunc lobortis non. Aliquam elementum quam at libero consequat malesuada. Maecenas dignissim lacus eu felis suscipit mattis.
      </p>
    </div>
  )
}

export default About