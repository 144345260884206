import React, { useState } from 'react'
import { Formik } from 'formik';
import './Login.scss'
import pic from '../../images/login-register-forgot.png'
import { Link, useHistory } from 'react-router-dom';
import firebase from 'firebase';
import { toast } from 'react-toastify';

export default function Register () {
  const [error, setError] = useState('')
  const history = useHistory()

  return (
    <div className="LoginPage">
      <div className="row my-4">
        <div className="col-md-12 col-lg-8 offset-md-0 offset-lg-2">
          <div className="row row-wrapper">
            <div
              className="col-md-6 pic d-none d-md-block"
              style={{ backgroundImage: `url(${pic})` }}></div>
            <div className="col-md-6 align-self-center p-4">
              <div>
                <h3 className="mb-4">register</h3>
                { error ? (
                  <div className="alert alert-danger">
                    {error}
                  </div>
                ) : undefined }
                <Formik
                  initialValues={{ email: '', password: '', confirm_password: '' }}
                  validate={values => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = 'Required';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                      errors.email = 'Invalid email address';
                    }
                    if (!values.password) {
                      errors.password = 'Required';
                    } else if (
                      !/.{6,}/g.test(values.password)
                    ) {
                      errors.password = 'Min 6 chars';
                    }
                    if (values.password != values.confirm_password) {
                      errors.confirm_password = 'Passwords do not match';
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setError('')

                    firebase.auth().createUserWithEmailAndPassword(values.email, values.password).then(res => {
                      toast.success('Welcome!', {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                      
                      history.push("/");
                    }).catch(err => {
                      // setError(err.message)
                      toast.error(err.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }).finally(() => {
                      setSubmitting(false)
                    })
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <input
                        type="email"
                        name="email"
                        className={"form-control" + ( errors.email ? " is-invalid" : "")}
                        placeholder="email address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <div class="invalid-feedback">
                        {errors.email && touched.email && errors.email}
                      </div>
                      <input
                        type="password"
                        name="password"
                        className={"form-control" + ( errors.password ? " is-invalid" : "")}
                        placeholder="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      <div class="invalid-feedback">
                        {errors.password && touched.password && errors.password}
                      </div>
                      <input
                        type="password"
                        name="confirm_password"
                        className={"form-control" + ( errors.confirm_password ? " is-invalid" : "")}
                        placeholder="confirm password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirm_password}
                      />
                      <div class="invalid-feedback">
                        {errors.confirm_password && touched.confirm_password && errors.confirm_password}
                      </div>
                      <div className="text-right">
                        <button
                          type="submit"
                          className="btn btn-danger"
                          disabled={isSubmitting}
                        ><i class="fas fa-arrow-right"></i></button>
                      </div>
                    </form>
                  )}
                </Formik>
                <div className="text-center mt-4">
                  <Link to="/login" className="color-orange">back to login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
