import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as helpers from '../../components/helpers'
import Loading from "../../components/Loading";
import Breadcrumbs from "../../components/Breadcrumbs"
import coverPic from '../../images/cover-pic.png'
import './Artists.scss'

function PrintArtists ({list}) {
  return (
    <div className="row">
      {list.items.map(artist => 
        <Link to={`/a/${artist.id}`} className="col-sm-6">
          <div class="card card-widget widget-user">
            <div class="widget-user-header text-white">
              <img src={artist.cover_pic ? artist.cover_pic : coverPic} />
            </div>
            <div class="widget-user-image">
              <img class="img-circle" src={artist.pic} />
            </div>
            <div class="card-footer">
              <div className="text-center">
                {artist.name}
              </div>
            </div>
          </div>
        </Link>
      )}
    </div>
  )
}

const Artists = () => {
  const db = firebase.firestore()
  const [ artists, setArtists ] = useState([])

  useEffect(() => {
    loadArtists()
  }, [])
  const loadArtists = async () => {
    const docs = await db.collection('artists').orderBy('artist').get()
    let list = []

    if(!docs.empty) {
      docs.forEach(doc => list.push({
        id: doc.id,
        name: doc.data().artist,
        cover_pic: doc.data().cover_pic,
        pic: doc.data().pic,
      }))

      setArtists(helpers.addFirstLetterToArtists(list))
    }
  }
  const styles = {
    backgroundPosition: `center center;`
  }
  return (
    <div>
      <h3>Artists</h3>
      <Breadcrumbs breadcrumbs={[{
        text: 'Artists'
      }]}/>
      <Loading loading={!artists.length}/>
      <div className="page-artists">
        {artists && artists.map(list => {
          return (
            <div key={list.letter} >
              <div className="letter"><div>{list.letter}</div></div>
              <PrintArtists list={list}/>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Artists