import React, { useEffect, useState } from 'react'
import Item from './Item';
import * as firebase from 'firebase'
import {
  selectUser,
} from "../../features/user/userSlice";
import { useSelector } from 'react-redux';
// 
import './Feed.scss';
import * as helpers from '../helpers'

export const FeedComponent = ({music, playing, setPlaying, PrintDateIfNeeded}) =>
  music.map((song, key) =>
    <div key={song.id}>
      <PrintDateIfNeeded date={song.date}/>
      <Item propSong={song} playing={playing && song.id === playing} setPlaying={id => setPlaying(id)}/>
    </div>
  )

const Feed = () => {
  const [ music, setMusic ] = useState([])
  const [ playing, setPlaying ] = useState(null)
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const user = useSelector(selectUser);
  const username = useSelector(state => state.user.username);
  const [ show, setShow ] = useState(15)
  let lastPrintedDate = null

  useEffect(() => {
    loadMusic()
  }, [])

  const loadMusic = (forceRefresh = false) => {
    let musicRef = firebase.firestore()
                              .collection('music')
                              .orderBy('created', 'desc')

    musicRef.onSnapshot(snap => {
      let list = []
      
      snap.docs.map(res => {
        list.push(helpers.transformItem(res))
      })

      setMusic(list)
    })
  }
  const refreshFeed = async () => {
    return
    await setMusic([])
    setTimeout(() => {
      loadMusic(true)
    }, 1000);
    console.log('refreshFeed');
  }
  /**
   * components
   */
  const LoadingComponent = () =>
    <div className="loading">
      <i class="fas fa-sync fa-spin fa-5x"></i>
    </div>
    
  const PrintDateIfNeeded = ({date}) => {
    let today = new Date()
    today = today.getDate() + ' ' + monthNames[today.getMonth()]

    if(date === today)
      date = 'today'

    if(date != lastPrintedDate){
      lastPrintedDate = date
      return (
        <div class="date">{ date }</div>
      )
    }

    return (<></>)
  }

  const showMore = (e) => {
    e.preventDefault()
    if(music.length > show + 15)
      setShow(show + 15)
    else
      setShow(music.length)
  }

  const partOfFeed = music.slice(0, show)

  return (
    <div>
      { user.userData.id && !username.loading && username.set && (
        { /* <SuggestSongButton /> */ }
      )}

      { music.length ? <FeedComponent music={partOfFeed} playing={playing} setPlaying={setPlaying} PrintDateIfNeeded={PrintDateIfNeeded}/> : <LoadingComponent />}

      <hr />
      showing: { show } of { music.length }
      { show < music.length ? (
        <div className="text-center">
          <a href="#" className="btn btn-primary btn-block" onClick={e => showMore(e)}>show more</a>
        </div>
      ):(<></>)}
    </div>
  )
}

export default Feed