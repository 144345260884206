import { useEffect } from "react";

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export function extractFirstLetter (name) {
  const firstLetter = name[0]
  var regex = /[A-z]/g;
  var match = firstLetter.match(regex);

  if(match) return firstLetter.toUpperCase()

  return '#'
}

export function addFirstLetterToArtists (artists) {
  let arr = []

  artists.map(artist => {
    // find element in firstLetter
    let el = arr.find(e => e.letter === extractFirstLetter(artist.name[0]))
    if(!el) {
      arr.push({
        letter: extractFirstLetter(artist.name[0]),
        items: [artist]
      })
    } else {
      el = arr.find(e => e.letter === extractFirstLetter(artist.name[0]))
      el.items.push(artist)
    }
  })
  return arr;
}
export function transformItem (document) {
  let item = document.data()
  let date = new Date()

  if(document.data().created)
    date = new Date(document.data().created.toDate());

  item.date = date
  item.id = document.id
  item.rev = item.rev ?? 0
  let song = {
    artist: null,
    date: null,
    featuring: null,
    id: null,
    likes: null,
    name: null,
    production: null,
    songName: null,
    uid: null,
    yid: null,
  }

  switch(item.rev) {
    case 2:
      let name = ''
      if(item.original && item.original.song)
        name = item.original.song

      if(item.songName)
        name = item.songName

      let artist = {
        id: null,
        name: ''
      }
      if(item.original && item.original.artist)
        artist.name = item.original.artist
      if(item.artist)
        artist = item.artist

      let yid = null
      if(item.original && item.original.yid)
        yid = item.original.yid
      if(item.yid)
        yid = item.yid

      song.artist = artist
      song.date = item.date.getDate() + ' ' + monthNames[item.date.getMonth()]
      song.featuring = item.featuring
      song.id = item.id
      song.likes = item.likes
      song.name = `${artist.name} - ${name}`
      song.production = item.production
      song.songName = name
      song.uid = item.uid
      song.yid = yid
      break
    case 1:
      song.artist = {
        id: null,
        name: item.original.artist
      }
      song.id = item.id
      song.name = `${item.original.artist} - ${item.original.song}`
      song.date = item.date.getDate() + ' ' + monthNames[item.date.getMonth()]
      song.uid = item.uid
      song.likes = item.likes
      song.songName = item.original.song
      song.yid = item.original.yid
      break
    default:
      song.id = item.id
      song.name = item.name
      song.date = item.date.getDate() + ' ' + monthNames[item.date.getMonth()]
      song.uid = item.user.uid
      song.likes = item.likes
      song.yid = null
  }

  return song
}

export function useOutsideAlerter (ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export function generateSongName (songName, featuring) {
  let featuringText = null

  if(featuring && featuring.length) {
    let feat = []
    featuring.map(artist => {
      feat.push(artist.name)
    })
    featuringText = feat.join(', ')
  }

  if(featuringText)
    return `${songName} (feat ${featuringText})`

  return songName
}