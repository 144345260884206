import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import ProfilePresentation from './Profile.presentation'
import { likesCollection } from '../../constants'
import { extractFirstLetter } from '../../components/helpers'

export default function Profile () {
  let { username } = useParams()
  const loadingUsers = useSelector(state => state.usernames.loading)
  const currentUser = useSelector(state => state.user.username)
  const users = useSelector(state => state.usernames.usernames)
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [songs, setSongs] = useState([])
  const [user, setUser] = useState(null)

  useEffect(() => {
    const userFound = users.find(user => user.username == username)

    setUser(userFound)
  }, [users])

  useEffect(() => {
    loadLikes()
  }, [user])
  const loadLikes = () => {
    if(!user) return

    const db = firebase.firestore()

    setBreadcrumbs([{
      text: 'Users',
      href: '/users'
    },{
      text: extractFirstLetter(user.username)
    },{
      text: user.username
    }])

    db.collection(likesCollection)
        .where('user.uid', '==', user.id)
        .orderBy('created_at', 'desc')
        .get()
        .then(res => {
          let list = []
          res.docs.forEach((doc) => {
            list.push({
              artist: doc.data().song.artist,
              created_at: doc.data().created_at,
              featuring: doc.data().song.featuring,
              id: doc.data().songId,
              songName: doc.data().song.songName,
            })
          })
          list = list.filter(song => song.artist)
          setSongs(list)
        })
  }

  return (
    <ProfilePresentation
      breadcrumbs={breadcrumbs}
      currentUser={currentUser}
      loading={loadingUsers}
      songs={songs}
      user={user}
    />
  )
}