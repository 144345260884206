import React from 'react';
import {
  setNav,
  selectNav,
} from '../features/app/appSlice';
import {
  Link
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
// 
import './Sidebar.scss';

export default function Sidebar() {
  const nav = useSelector(selectNav);
  const dispatch = useDispatch();

  return (
    <nav className={"collapse bd-links" + (nav ? ' show' : '') }>
      <header className="d-md-none">
        <li className="nav-item d-inline-block">
          <a href="/#" className="nav-link" onClick={e => {e.preventDefault(); dispatch(setNav(!nav))}}>
            <i className="fas fa-times" aria-hidden="true"></i>
          </a>
        </li>
        {/* close */}
      </header>
      <div>
        <div className="list-group list-group-flush">
          <Link to="/" className="list-group-item list-group-item-action">
            <span className="sidebarIcon">
              <i className="fa fa-home"></i>
            </span> home
          </Link>
          <Link to="/users" className="list-group-item list-group-item-action">
            <span className="sidebarIcon">
              <i className="fas fa-users"></i>
            </span> users
          </Link>
          <Link to="/artists" className="list-group-item list-group-item-action">
            <span className="sidebarIcon">
              <i class="fas fa-microphone"></i>
            </span> artists
          </Link>
        </div>
      </div>
    </nav>
  )
}
