import React, { useEffect } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import FeedSong from '../../components/Feed/Feed/FeedSong'
import './Profile.scss'

export default function ProfilePresentation ({breadcrumbs, currentUser, loading, songs, user}) {
  if(loading)
    return (
      <div>loading</div>
    )
  if(!user)
    return (
      <div>user not found</div>
    )

  return (
    <div className="Profile">
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      { currentUser.username === user.username ? 
        <a href="/edit-profile" className="float-right"><i class="fas fa-pencil-alt"></i> edit profile</a>
        : <span></span>
      }
      <div className="row">
        <div className="col-lg-3">
          { user.profilePic ?
            <img src={user.profilePic} className="w-100 profile-pic"/>
            : <img src={"https://avatars.dicebear.com/4.5/api/bottts/" + user.username + ".svg"} className="w-100"/>
          }
          <h3 className="mt-3">
            {user.username}
          </h3>
          <p className="description">{user.description}</p>
        </div>
        <div className="col-lg-9">
          <h3>Playlist</h3>
          {songs.map(song =>
            <FeedSong song={song}/>
          )}
          {!songs.length && <div>no songs</div>}
        </div>
      </div>
    </div>
  )
}