import React, {
  useEffect,
  useState,
} from 'react'
import * as FirebaseActions from '../../FirebaseActions'
import * as firebase from "firebase";
import { useDispatch, useSelector } from 'react-redux';
import { setUsername } from '../../features/user/userSlice';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

export default function SetUsername () {
  const uid = useSelector(state => state.user.userData.id)
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setTimeout(async () => {
      const token = await firebase.auth().currentUser.getIdToken()
      console.log(token);
    }, 1000);
  }, [])

  return (
    <div className="row">
      <div className="col-md-6 offset-md-3">
        <h4>Set profile</h4>
        <p>
          Welcome user, here you can set your username.<br />
          You can set it one time only, so beware.

        </p>
        <p>
          <strong>some rules:</strong>
          <ul>
            <li>min: 5</li>
            <li>max: 15</li>
            <li>allowed characters: a-z, 0-9, _</li>
          </ul>
        </p>

        <Formik
          initialValues={{ username: '' }}
          validate={values => {
            const errors = {};

            if (!values.username) {
              errors.username = 'Required';
            } else if (
              !/.{5,}/i.test(values.username)
            ) {
              errors.username = 'Enter a minimum of 5 characters';
            } else if (
              /.{16,}/i.test(values.username)
            ) {
              errors.username = 'Enter a maximum of 15 characters';
            } else if (
              !/^[A-z0-9_]{5,15}$/i.test(values.username)
            ) {
              errors.username = 'Allowed characters: letters, numbers and underscore';
            }
            
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            FirebaseActions.saveUsername(firebase, uid, values.username)
            .then(res => {
              dispatch(setUsername({
                set: true,
                username: res
              }))
              history.push("/");
            })
            .catch(msg => {
              alert(msg)
            })
            .finally(() => {
              setSubmitting(false);
            })
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} autocomplete="off">
              <div class="input-group">
                <input
                  type="text"
                  name="username"
                  class="form-control bg-dark"
                  placeholder="Enter username here"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  autocomplete="off" />
                <button type="submit" class="btn btn-danger" disabled={isSubmitting}>
                  Save
                </button>
              </div>
              <span className="text-red">
                {errors.username && touched.username && errors.username}
              </span>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}