import React from 'react'

export default function SuggestedSongsComp ({items, loading}) {
  return (
    <div>
      <h3>Suggested Songs</h3>
      {loading ? (
        <div>loading</div>
      ) : items.map(item =>
        <div key={item.id}>
          <Badge action={item.action} />
          <strong>{item.artist}</strong> - {item.song}
        </div>
      )}
    </div>
  )
}

function Badge ({action}) {
  if(action && action.status == 'approved')
    return <span className="badge badge-success mr-1">approved</span>
  return <span></span>
}