import React from 'react'
import moment from 'moment'

export default function ArtistInformationPanel ({artist}) {
  return (
    <div className="col-sm-4 order-sm-2 p-lg-3">
      <div className="information-panel p-1 p-md-1 p-lg-2">
        <div className="text-center profile-pic">
          <img src={artist.pic} />
        </div>
        <table className="mt-3">
          <tr>
            <td className="pr-2 font-weight-bold">Born</td>
            <td>{moment(artist.born).format('MMMM Do YYYY')}</td>
          </tr>
          <tr>
            <td className="pr-2 font-weight-bold">Other names</td>
            <td>{artist.other_names}</td>
          </tr>
          <tr>
            <td className="pr-2 font-weight-bold">Occupation</td>
            <td>{artist.occupation}</td>
          </tr>
          <tr>
            <td className="pr-2 font-weight-bold">Years active</td>
            <td>{artist.years_active}</td>
          </tr>
        </table>
      </div>
    </div>
  )
}