import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './ArtistLink.scss'

export default function ArtistLink ({artist}) {
  const [showInfo, setShowInfo] = useState(false)
  if(artist && artist.id)
    return (
      <span
        className="ArtistLink"
        onMouseEnter={() => setShowInfo(true)}
        onMouseLeave={() => setShowInfo(false)}
      >
        <Link to={"/a/" + artist.id}>{artist.name}</Link>
        {/* <div className={"info" + (!showInfo ? " d-none" : "")}>info</div> */}
      </span>
    )
  if(artist)
    return (
      <span>{artist.name}</span>
    )
  return (
    <span>loading</span>
  )
}