import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null
}
export const slice = createSlice({
  name: 'player',
  initialState: initialState,
  reducers: {
    startPlayer: (state, action) => {
      state.id = action.payload
    },
    stopPlayer: (state) => {
      state.id = null
    },
  },
});

export const { startPlayer, stopPlayer } = slice.actions;

export default slice.reducer;
