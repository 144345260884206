import React, { useState } from 'react'
import Item from '../Item'

const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];
let lastPrintedDate = null

export default function FeedMusicComp ({music, playing, setPlaying}) {
  const PrintDateIfNeeded = ({date}) => {
    let today = new Date()
    today = today.getDate() + ' ' + monthNames[today.getMonth()]

    if(date === today)
      date = 'today'

    if(date != lastPrintedDate){
      lastPrintedDate = date
      return (
        <div class="date">{ date }</div>
      )
    }

    return (<></>)
  }

  return (
    music.map((song, key) =>
      <div key={song.id}>
        <PrintDateIfNeeded date={song.date}/>
        <Item propSong={song} playing={playing && song.id === playing} setPlaying={id => setPlaying(id)}/>
      </div>
    )
  )
}