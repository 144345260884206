import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  usernames: []
}
export const slice = createSlice({
  name: 'usernames',
  initialState: initialState,
  reducers: {
    setUsernames: (state, action) => {
      state.loading = false;
      state.usernames = action.payload;
    },
  },
});

export const { setUsernames } = slice.actions;

export default slice.reducer;
