import React, { useState } from 'react';
import {
  selectUserData,
} from "../../features/user/userSlice";
import { useSelector } from 'react-redux';
import * as firebase from 'firebase'
import './AddItem.scss'
import { toast } from 'react-toastify';
import * as FirebaseActions from '../../FirebaseActions'

function AddItem(props) {
  const [ item, setItem ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ success, setSuccess ] = useState(false)
  const [ error, setError ] = useState(false)
  const userData = useSelector(selectUserData)
  const username = useSelector(state => state.user.username.username)

  const handleSubmit = () => {
    console.log('submitting ', item, userData);
    setLoading(true)
    setSuccess(false)
    setError(false)
    
    if(!userData.id || !username)
      return toast.error('log in and set username')

    const data = {
      name: item,
      user: {
        uid: userData.id,
      },
      created: firebase.firestore.FieldValue.serverTimestamp(),
    }
    firebase.firestore().collection('music').add(data)
    .then(res => {
      setLoading(false)
      setSuccess(true)
      setItem('')

      console.log(res);
      props.refreshFeed()

      FirebaseActions.likeSong(username, userData.id, res.id)
      .then( res => {
        console.log(res);
      })
      .catch(res => {
        toast.error('an error occured')
      })
    })
    .catch(err => {
      setLoading(false)
      setError(true)
    })
  }
  return (
    <div className="mb-3 add-item">
      <h4>Add a song</h4>
      
      { error &&
        <div className="alert alert-danger">
          You have an error: the song must be at least 5 characters and not more than 100.
        </div>
      }
      { success &&
        <div className="alert alert-success">
          Song added
        </div>
      }
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control bg-dark"
          placeholder="Song name"
          value={item}
          onChange={event => setItem(event.target.value)} />
        <div class="input-group-append">
          <button class="btn btn-danger" type="button" onClick={() => handleSubmit()} disabled={ loading ? true : false}>
            { loading ? (
              <i class="fas fa-circle-notch fa-spin"></i>
            ):(
              <span>add</span>
            )}
            </button>
        </div>
      </div>
    </div>
  )
}

export default AddItem