import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as FirebaseActions from '../../FirebaseActions'
import './Item.scss';
import ArtistLink from '../ArtistLink';
import { useMutation } from 'react-query';

const Likes = ({likes}) => {
  if(!likes || !likes.no)
    return (
      <></>
    )
  const newLikes = [...likes.last3]
  const firstItem = newLikes.splice(0, 1)
  
  return (
    <>
      <span><i className="far fa-heart"></i> {firstItem}</span>
      {newLikes.map((username, key) => 
        <span key={key}>
          , {username}
        </span>
      )}
    </>
  )
}

function PrintLike({liked, song}) {
  const mutation = useMutation(data => {
    return FirebaseActions.like(data.action, data.songId)
  })
  const [animate, setAnimate] = useState(false)

  const userId = useSelector(state => state.user.userData.id)
  const username = useSelector(state => state.user.username)

  const likeSong = (event) => {
    event.preventDefault()
    setAnimate(true)
    setTimeout(() => {
      setAnimate(false)
    }, 1000);

    if(!userId) {
      return toast.error('log in first!')
    }
    if(!username.set) {
      return toast.error('username not set')
    }

    mutation.mutate({
      action: 'like',
      songId: song.id
    })
  }

  const dislikeSong = (event) => {
    event.preventDefault()
    setAnimate(true)
    setTimeout(() => {
      setAnimate(false)
    }, 1000);

    if(!userId) {
      return toast.error('log in first!')
    }
    if(!username.set) {
      return toast.error('username not set')
    }

    mutation.mutate({
      action: 'dislike',
      songId: song.id
    })
  }

  if(mutation.isLoading)
    return (
      <div className="like-song-button isLoading">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
    )

  if(liked)
    return (
      <div className="like-song-button">
        <a className={(animate ? " animate__bounceIn" : "")} href="#" onClick={dislikeSong}>
          <i className="fas fa-fire liked"></i>
        </a>
      </div>
    )
  return (
    <div className="like-song-button">
      <a className={(animate ? " animate__bounceIn" : "")} href="#" onClick={likeSong}>
        <i className="fas fa-fire outline"></i>
      </a>
    </div>
  )
  
}

function Item({ propSong, playing, setPlaying }) {
  const [ yid, setYid ] = useState(null) // UQIyK_HCZGE
  const [song, setSong] = useState({})
  const [featuring, setFeaturing] = useState(null)
  const likes = useSelector(state => state.user.likes)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if(!playing)
      setYid(null)

    setSong(propSong)

    if(propSong.featuring && propSong.featuring.length) {
      let feat = []
      propSong.featuring.map(song => {
        feat.push(song.name)
      })
      setFeaturing(feat.join(', '))
    }
  }, [propSong.likes]);

  const handleOpen = () => {
    history.push({
      pathname: `/song/${song.id}`,
      state: { background: location }
    })
  }

  const printFeaturing = () => {
    if(featuring)
      return <span> (feat. {featuring})</span>
    return <span></span>
  }

  return (
    <div className="song">
      <div className="d-flex">
        <div 
          className="play-button cursor-pointer"
          onClick={() => handleOpen()}
        >
          {
            playing
            ? <i className="far fa-stop-circle"></i>
            : <i className="far fa-play-circle"></i>
          }
        </div>
        <div
          className="details w-100"
          >
          {song.artist && song.artist.id ?
            <div>
              <ArtistLink artist={song.artist}/>
              <span> - </span>
              <span
                className="cursor-pointer"
                onClick={() => handleOpen()}
              >{ song.songName }{ printFeaturing() }</span>
            </div>
            : <div><span
                className="cursor-pointer"
                onClick={() => handleOpen()}
              >{ song.name }{ printFeaturing() }</span></div>
          }
          <small>
            <Likes likes={song.likes}/>
          </small>
        </div>
        <PrintLike
          liked={likes.find(like => like.songId === song.id)}
          song={song}
        />
      </div>

      <div className={"expanded " + (playing ? '' : 'minimize')}>
        { yid === null ? (
          <div style={{ height: 315 }}>loading</div>
        ):(
          <iframe
            width="100%"
            height="315"
            src={"https://www.youtube.com/embed/" + yid + "?autoplay=1"}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            autoplay="1"></iframe>
        )}
      </div>
    </div>
  )
}

export default Item