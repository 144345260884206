import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  userData: {
    uid: null,
    email: null,
  },
  username: {
    loading: true,
    set: false,
    username: null,
  },
  likes: []
}
export const slice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      state.username = initialState.username;
    },
    setUsername: (state, action) => {
      state.username.loading = false;
      state.username.set = action.payload.set;
      state.username.username = action.payload.username;
    },
    setLikes: (state, action) => {
      state.likes = action.payload;
    },
  },
});

export const { setUser, setUsername, setLikes } = slice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUser = state => state.user;
export const selectUserData = state => state.user.userData;

export default slice.reducer;
