import React, { useEffect } from 'react';
import './App.scss';
import Feed from './components/Feed/Feed'
import Blog from './components/Blog/Blog'
import About from './components/About/About'
import AddItem from './components/Feed/AddItem'
import Item from './components/Feed/Item'
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import {
  Login,
  PasswordReset,
  Register,
} from './pages/Login'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as firebase from "firebase";
import SetUsername from './components/User/SetUsername';
import * as FirebaseActions from './FirebaseActions'
import { useDispatch, useSelector } from 'react-redux';
import {
  setUsernames
} from "./features/usernames/usernamesSlice";
import Users from './components/Users/Users';
import Profile from './features/Profile/Profile';
import EditProfile from './components/User/EditProfile';
import SongModal from './components/SongModal/SongModal';
import AddSong from './pages/AddSong/AddSong';
import { Artists, Artist } from './pages'
import Player from './features/player/Player';
import SongModalWrapper from './SongModalWrapper';
import FeedWrapper from './components/Feed/Wrapper/FeedWrapper';

const firebaseConfig = {
  apiKey: "AIzaSyClGjAOQQRdZJEtkc6jvaO2ggFCCsRM0kU",
  authDomain: "rapbase-280320.firebaseapp.com",
  databaseURL: "https://rapbase-280320.firebaseio.com",
  projectId: "rapbase-280320",
  storageBucket: "rapbase-280320.appspot.com",
  messagingSenderId: "934238614920",
  appId: "1:934238614920:web:765c42f5e4cf66011ff63d",
};

firebase.initializeApp(firebaseConfig)

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // if we wanna use FirebaseActions, observables
    const db = firebase.firestore()
    const ref = db.collection('usernames')
    
    ref.onSnapshot( snap => {
      let data = []

      snap.docs.forEach(doc => {
        data.push({
          id: doc.data().uid,
          username: doc.id,
          profilePic: doc.data().profilePic,
          description: doc.data().description
        })
      })
      dispatch(setUsernames(Object.assign([], data)))
    })
  }, [])

  return (
    <Router>
      <Header />
      <div className="container-md" style={{ overflow: 'hidden' }}>
        <ModalSwitch />
      </div>
      <footer>
        gruvee v1.0.0<br />
        coded with <i class="fas fa-heart"></i> by praph in 2020 - { new Date().getFullYear() }
      </footer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
}

function ModalSwitch() {
  let location = useLocation()
  let background = location.state && location.state.background;

  return (
    <div>
      <Switch location={background || location}>
        <Route path="/" exact>
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9">
              <FeedWrapper />
            </div>
          </div>
        </Route>
        <Route path="/blog" exact>
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9">
              <Blog />
            </div>
          </div>
        </Route>
        <Route path="/add-song" exact>
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9">
              <AddSong />
            </div>
          </div>
        </Route>
        <Route path="/about" exact>
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9">
              <About />
            </div>
          </div>
        </Route>
        <Route path="/users" exact>
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9">
              <Users />
            </div>
          </div>
        </Route>
        <Route path="/u/:username" exact>
          <Profile />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/set-username">
          <SetUsername />
        </Route>
        <PrivateRoute path="/edit-profile">
          <EditProfile />
        </PrivateRoute>
        <Route path="/artists" exact>
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9">
              <Artists />
            </div>
          </div>
        </Route>
        <Route path="/a/:artist" exact>
          <Artist />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/forgot-password">
          <PasswordReset />
        </Route>
      </Switch>
      <SongModalWrapper />
    </div>
  )
}
const PrivateRoute = (props) => {
  const user = useSelector(state => state.user)

  return (
    <>
      { user.loading ? (
        <div className="loading-container">
          <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
      ) : (
        user.userData.id ? (
          <Route {...props}>
            { props.children }
          </Route>
        ):(
          <>
            log in first
          </>
        )
      ) }
    </>
  )
}

export default App;
