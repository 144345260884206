import React from 'react'
import { useDispatch } from 'react-redux'
import { stopPlayer } from '../../features/player/playerSlice'

export default function SongModalNav({expandCollapse, fullscreen, song}) {
  const dispatch = useDispatch()
  
  const expand = e => {
    e.preventDefault()

    expandCollapse()
  }

  const close = e => {
    e.preventDefault()

    dispatch(stopPlayer())
  }

  return (
    <nav className="navbar navbar-expand navbar-dark">
      <ul className="navbar-nav">
        <li className="nav-item active">
          <a href="#" className="nav-link navbar-brand" onClick={expand}>
            {fullscreen ? 
              <i class="fas fa-chevron-down"></i>
              :<i class="fas fa-chevron-up"></i>
            }
          </a>
        </li>
      </ul>
      <ul className="navbar-nav overflow-hidden mr-auto">
        <li className="nav-item active">
          { song ?
            <span className="nav-link whitespace-nowrap" onClick={e => e.preventDefault()}>
              { song.name }
            </span>
          : <div></div>
          }
        </li>
      </ul>
      { !fullscreen ? 
      <ul className="navbar-nav ml-auto">
        <li className="nav-item active">
          <a href="#" className="nav-link" onClick={close}>
            <i class="fas fa-times"></i>
          </a>
        </li>
      </ul>
      : undefined }
    </nav>
  )
}