import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as FirebaseActions from '../../FirebaseActions'
import { toast } from 'react-toastify';
import firebase from 'firebase';
import './AddSong.scss'

export default function Step2 ({song, setStep}) {
  const [ yid, setYid ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const userData = useSelector(state => state.user.userData)
  const username = useSelector(state => state.user.username.username)

  const loadSong = async () => {
    console.log(song);
    const yid = await FirebaseActions.loadYoutubeSong(song.artist + ' - ' + song.song)
    setYid(yid)
  }

  useEffect(() => {
    loadSong()
  }, [])

  const handleSubmit = () => {
    setLoading(true)
    
    if(!userData.id || !username)
      return toast.error('log in and set username')

    const data = {
      original: {
        artist: song.artist,
        song: song.song,
        yid: yid,
      },
      uid: userData.id,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      rev: 1
    }
    firebase.firestore().collection('suggested-songs').add(data)
    .then(res => {
      setLoading(false)
      setStep(3)

      // FirebaseActions.likeSong(username, userData.id, res.id)
      // .then( res => {
      // })
      // .catch(res => {
      //   toast.error('an error occured')
      // })
    })
    .catch(err => {
      alert('an error occured')
    })
  }

  return (
    <div>
      { yid ? 
        <iframe width="100%" height="350" src={"https://www.youtube.com/embed/" + yid + '?autoplay=1'} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        : <div>loading</div>}
      <div className="row">
        <div className="col-6">
          <button
            className="btn btn-default my-5"
            type="submit"
            onClick={() => setStep(1)}
            disabled={loading}
          >back</button>
        </div>
        <div className="col-6 text-right">
          <button
            className="btn btn-danger my-5"
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >upload</button>
        </div>
      </div>
    </div>
  )
}
