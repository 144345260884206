import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../features/app/appSlice';
import userReducer from '../features/user/userSlice';
import usernamesReducer from '../features/usernames/usernamesSlice';
import playerReducer from '../features/player/playerSlice';

export default configureStore({
  reducer: {
    app: appReducer,
    player: playerReducer,
    user: userReducer,
    usernames: usernamesReducer,
  },
});
