import React, { useEffect, useState } from 'react'
import moment from 'moment'
import './FeedUser.scss'

export default function FeedUser ({action, date, username}) {
  const [icon, setIcon] = useState(null)
  const [actionText, setActionText] = useState(null)
  const [formattedDate, setFormattedDate] = useState(null)

  useEffect(() => {
    setFormattedDate(moment(date.toDate()))
  }, [date])

  return (
    <div className="FeedUser d-flex mt-3">
      <div className="mr-auto">
        <PrintAction
          action={action}
          username={username}
        />
      </div>
      <div className="feed-user-date">
        {formattedDate && formattedDate.format('D MMM YYYY')}
      </div>
    </div>
  )
}

function PrintAction ({action, username}) {
  if(action === 'song_like')
    return <span>
      <i className={'fas fa-fire'} aria-hidden="true"></i>
      <span> {username} liked a song</span>
    </span>
  if(action === 'song_approved')
    return <span>
      <i class="fas fa-folder-plus"></i>
      <span> a song by {username} has been approved</span>
    </span>

  return <span>
    <i className={'fas fa-fire'} aria-hidden="true"></i>
    {username} liked a song
  </span>
}