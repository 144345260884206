import React, { useEffect, useState } from 'react'
import './AddSong.scss'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

export default function Step3 ({song, back}) {
  return (
    <div className="add-song">
      <div className="text-center">
        <i class="fas fa-thumbs-up fa-10x text-secondary-1"></i><br />
        <div className="mt-5">Your suggestion was submitted.</div>
        <div>Once it's approved, you can see it on the <Link to="/" className="color-orange">homepage</Link>.</div>
      </div>
    </div>
  )
}