import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import './SongModal.scss'
import SongModalFeed from './SongModalFeed'
import SongModalInfo from './SongModalInfo'
import SongModalNav from './SongModalNav'

export default function SongModal({fullscreen, loading, song, yid}) {
  const history = useHistory()
  const location = useLocation()
  const background = location.state && location.state.background;

  const expandCollapse = () => {
    if(fullscreen) {
      if(background && background.pathname)
        history.push(background.pathname)
      else
        history.push('/')
    } else {
      history.push({
        pathname: `/song/${song.id}`,
        state: { background: location }
      })
    }
  }
  const prevent = (e) => {
    e.stopPropagation()
  }
  return (
    <div className={fullscreen ? "song-modal-wrapper" : ""} onClick={expandCollapse}>
      <div className={fullscreen ? "container" : ""} onClick={prevent}>
        { fullscreen ?
          <SongModalNav
            expandCollapse={expandCollapse}
            fullscreen={fullscreen}
            song={song}
          />
          :<div></div>
        }
        <div className={fullscreen ? "row" : "minimized-player"}>
          { !fullscreen ? (
            <SongModalNav
              expandCollapse={expandCollapse}
              fullscreen={fullscreen}
              song={song}
            />
            ):<div></div>
          }
          <div className={fullscreen ? "col-md-8" : ""}>
            { yid ? 
              <iframe width="100%" height={fullscreen ? 350 : 250} src={"https://www.youtube.com/embed/" + yid + '?autoplay=1'} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              : <div>loading</div>}
          </div>
          { fullscreen ?
            <div className="col-md-4">
              <SongModalInfo song={song} />
              { song ?
              <SongModalFeed song={song}/>
              : <div></div> }
            </div>
            :<div></div>
          }
        </div>
      </div>
    </div>
  )
}