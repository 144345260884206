import React, { useEffect, useState } from 'react'
import FeedSong from './FeedSong'
import FeedUser from './FeedUser'

export default function FeedComponent ({activity}) {
  return (
    <div className="Feed">
      {activity.map(action => 
        <>
          <FeedUser
            action={action.action}
            date={action.created_at}
            username={action.user.username}
          />
          <FeedSong song={{...action.target, id: action.targetId}}/>
        </>
      )}
    </div>
  )
}