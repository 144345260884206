import React, { useState } from 'react'
import { Formik } from 'formik';
import './Login.scss'
import pic from '../../images/login-register-forgot.png'
import { Link } from 'react-router-dom';
import firebase from 'firebase';

export default function PasswordReset () {
  const [success, setSuccess] = useState(false)

  return (
    <div className="LoginPage">
      <div className="row my-4">
        <div className="col-md-12 col-lg-8 offset-md-0 offset-lg-2">
          <div className="row row-wrapper">
            <div
              className="col-md-6 pic d-none d-md-block"
              style={{ backgroundImage: `url(${pic})` }}></div>
            <div className="col-md-6 align-self-center p-4">
              <div>
                <h3 className="mb-4">forgot password</h3>
                { success ? (
                  <div className="alert alert-success">
                    if there is an account with this email address
                    you'll receive instructions on how to reset your password
                  </div>
                ):(
                  <Formik
                    initialValues={{ email: '' }}
                    validate={values => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = 'Required';
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                      ) {
                        errors.email = 'Invalid email address';
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      firebase.auth().sendPasswordResetEmail(values.email)
                      .finally(res => {
                        setSuccess(true)
                      })
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="input-group">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="enter email address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <button
                            type="submit"
                            className="btn btn-danger"
                            disabled={isSubmitting}
                          ><i class="fas fa-arrow-right"></i></button>
                        </div>
                        <div className="error-placeholder">
                          {errors.email && touched.email && errors.email}
                        </div>
                      </form>
                    )}
                  </Formik>
                )}
                <div className="text-center mt-4">
                  <Link to="/login" className="color-orange">back to login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
