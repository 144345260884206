import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import './LikesFeed.scss'
import TimeAgo from 'react-timeago'
import { likesCollection } from '../../constants'

export default function LikesFeed({songId}) {
  const db = firebase.firestore()
  const [ likes, setLikes ] = useState([])

  useEffect(() => {
    const subscriber = db.collection(likesCollection)
                          .where('songId', '==', songId)
                          .orderBy('created_at', 'desc')
                          .onSnapshot(snapshot => {
                            const list = []
                            snapshot.forEach(doc => {
                              const date = new Date(doc.data().created_at.toDate());
                              
                              list.push({
                                id: doc.id,
                                created_at: date,
                                username: doc.data().user.username,
                              })
                            })
                            setLikes(list)
                          })

    return () => {
      subscriber()
    }
  }, [songId])
  return (
    <div className="likes-feed">
      <div className="likes-feed-header">
        <i className="fas fa-fire"></i>-o-meter
      </div>
      { likes && likes.map(like =>
        <div className="d-flex" key={ like.id }>
          <div className="mr-auto">{ like.username }</div>
          <small>
            <TimeAgo date={like.created_at} />
          </small>
        </div>
      )}
    </div>
  )
}