import React, { useEffect, useState } from 'react'
// 
import './Blog.scss';

const Blog = () => {
  const [ music, setMusic ] = useState([])
  const [ playing, setPlaying ] = useState(null)
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  let lastPrintedDate = null

  return (
    <div>
      <h3>Blog</h3>
      As you probably already know, gruvee is still under development.
      If you are interested about details, you can see the changelog here.

      <hr />
      <h4 className="float-left mr-2">
        v0.4.0
      </h4>
      <small className="badge bg-secondary">07sep2021</small>
      <div className="clearfix"></div>
      <ul>
        <li>Profiles done</li>
      </ul>

      <hr />
      <h4 className="float-left mr-2">
        v0.3.0
      </h4>
      <small className="badge bg-secondary">11aug2021</small>
      <div className="clearfix"></div>
      <ul>
        <li>Heart system</li>
      </ul>

      <hr />
      <h4 className="float-left mr-2">
        v0.2.0
      </h4>
      <small className="badge bg-secondary">16jan2021</small>
      <div className="clearfix"></div>
      <ul>
        <li>Dummy user page</li>
        <li>Match users for frontpage</li>
      </ul>

      <hr />
      <h4 className="float-left mr-2">
        v0.1.1
      </h4>
      <small className="badge bg-secondary">14jan2021</small>
      <div className="clearfix"></div>
      <ul>
        <li>Set username after user login</li>
        <li>Get all users on page load</li>
        <li>WIP: users page</li>
      </ul>

      <hr />
      <h4 className="float-left mr-2">
        v0.1.0
      </h4>
      <small className="badge bg-secondary">16sept2020</small>
      <div className="clearfix"></div>
      <ul>
        <li>New dark design</li>
      </ul>
      <div className="row">
        <div className="col-sm-6">
          <img src={require('./before.png')} style={{ width: "100%"}}/>
        </div>
        <div className="col-sm-6">
          <img src={require('./after.png')} style={{ width: "100%"}}/>
        </div>
      </div>
    </div>
  )
}

export default Blog