import React from 'react'
import { useHistory, useLocation } from 'react-router';
import { generateSongName } from '../../helpers';
import './FeedSong.scss'

export default function FeedSong ({song}) {
  const history = useHistory()
  const location = useLocation()

  const handleOpen = () => {
    history.push({
      pathname: `/song/${song.id}`,
      state: { background: location }
    })
  }

  return (
    <div className="FeedSong cursor-pointer" onClick={() => handleOpen()}>
      <div className="icon">
        <i className="far fa-play-circle" aria-hidden="true"></i>
      </div>
      <div className="song-details">
        <div className="artist">
          {song && song.artist.name}
        </div>
        <div className="songname">
          {song && generateSongName(song.songName, song.featuring)}
        </div>
      </div>
    </div>
  )
}