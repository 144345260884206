import React, { useEffect } from 'react'
import ArtistLink from '../ArtistLink';

export default function SongModalInfo ({song}) {
  return (
    <table className="table">
      { song && song.artist ?
        <tbody>
          <tr>
            <th>song by</th>
            <td><ArtistLink artist={song.artist}/></td>
          </tr>
        </tbody>
      : <tbody></tbody>
      }
      { song && song.featuring && song.featuring.length ?
        <tbody>
          <tr>
            <th>featuring</th>
            <td>
              { song.featuring.map((feat, key) => {
                return (
                  <span>
                    { key ? <span> • </span> : <span></span>}
                    <ArtistLink artist={feat}/>
                  </span>
                )
              })}
            </td>
          </tr>
        </tbody>
      : <tbody></tbody> }
      { song && song.production && song.production.length ?
        <tbody>
          <tr>
            <th>production</th>
            <td>
              { song.production.map((feat, key) => {
                return (
                  <span>
                    { key ? <span> • </span> : <span></span>}
                    <ArtistLink artist={feat}/>
                  </span>
                )
              })}
            </td>
          </tr>
        </tbody>
      : <tbody></tbody> }
    </table>
  )
}