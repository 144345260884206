import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as FirebaseActions from '../../FirebaseActions'
import LikesFeed from '../LikesFeed/LikesFeed'
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

export default function SongModalFeed({song}) {
  const mutation = useMutation(data => {
    return FirebaseActions.like(data.action, data.songId)
  })

  const likes = useSelector(state => state.user.likes)
  const [ liked, setLiked ] = useState(false)
  const userId = useSelector(state => state.user.userData.id)
  const username = useSelector(state => state.user.username)

  useEffect(() => {
    // console.log(likes, song);
    const likeFound = likes.find(like => like.songId === song.id)
    if(likeFound)
      setLiked(true)
    else
      setLiked(false)
  }, [likes, song])

  const like = () => {
    if(!userId) {
      return toast.error('log in first!')
    }
    if(!username.set) {
      return toast.error('username not set')
    }

    mutation.mutate({
      action: liked ? 'dislike' : 'like',
      songId: song.id
    })
  }

  return (
    <div>
      <button className={"btn btn-like btn-block my-3" + (liked ? ' active' : '')} onClick={like} disabled={mutation.isLoading}>
        {mutation.isLoading ?
          <i className="fas fa-spinner fa-spin"></i>
          : <i className="fas fa-fire"></i>
        } like
      </button>
      <LikesFeed songId={song.id}/>
    </div>
  )
}