import * as firebase from 'firebase'
import axios from 'axios'
import * as helpers from './components/helpers'

export const loadUsername = (_, uid) => {
  return new Promise((resolve, reject) => {
    const db = firebase.firestore()
    const ref = db.collection('usernames').where('uid', '==', uid)
    
    ref.get()
    .then(res => {
      if(res.empty){
        resolve({
          set: false,
          username: null,
        })
      } else {
        const doc = res.docs[0]

        resolve({
          set: true,
          username: doc.id,
        })
      }
    })
  })
}

export const like = async (action, songId) => {
  const token = await firebase.auth().currentUser.getIdToken(true)
  const url = `https://us-central1-rapbase-280320.cloudfunctions.net/like/rapbase/${songId}/${action}`

  if(action !== 'like' && action !== 'dislike')
    return

  return axios.post(url, {},
  {
    headers: {
      Authorization: token
    }
  })
  .then(res => res.data)
}
export const saveUsername = (_, uid, username) => {
  const db = firebase.firestore()

  return new Promise(async (resolve, reject) => {
    if(username === '') {
      reject('nop')
      return;
    }
    const token = await firebase.auth().currentUser.getIdToken(true)
    axios.post('https://us-central1-rapbase-280320.cloudfunctions.net/setUsername/' + username, {},
    {
      headers: {
        Authorization: token
      }
    })
    .then(res => {
      resolve(res.data.username);
    })
    .catch(res => {
      return reject(res.response.data.code);
    })
  })
}

export const likeSong = (username, uid, songId) => {
  return like(songId, 'like')
}
export const dislikeSong = (username, songId) => {
  return like(songId, 'dislike')
}
export const loadSongsWithId = (ids) => {
  const db = firebase.firestore()

  let resultArray = []
  Object.keys(ids).map((item, index) => {
    const chunkIndex = Math.floor(index/10)
    if(!resultArray[chunkIndex]) resultArray[chunkIndex] = []
    resultArray[chunkIndex].push(item)
  })

  return new Promise(async (resolve, reject) => {
    const ref = db.collection('music')
    let likes = []

    Promise.all(
      resultArray.map(item => {
        // return;
        return ref.where(firebase.firestore.FieldPath.documentId(), 'in', item).get()
        .then(res => {
          let songs = []
          res.docs.map(doc => {
            songs.push(helpers.transformItem(doc))
          })
          return songs;
        })
      })
    ).then(r => {
      let result = []

      r.map(arr => {
        arr.map(item => {
          item.created_at = new Date(ids[item.id].created_at.toDate())
          result.push(item)
        })
      })

      result = result.sort((a, b) => a.created_at > b.created_at ? -1 : 1)
      resolve(result)
    })
  })
}
export const loadProfile = (username) => {
  const db = firebase.firestore()

  return new Promise(async (resolve, reject) => {
    const ref = db.collection('usernames')
                  .doc(username)
                  
    const doc = await ref.get()

    if(doc.exists)
      resolve(doc.data())
    else
      reject()
  })
}
export const updateProfile = (user) => {
  const db = firebase.firestore()

  return new Promise(async (resolve, reject) => {
    const ref = db.collection('usernames')
                  .doc(user.username)

    const data = user

    ref.update(data)
    .then(() => {
      resolve()
    })
    .catch(err => {
      reject()
    })
  })
}

export const loadSong = (id) => {
  const db = firebase.firestore()

  return new Promise(async (resolve, reject) => {
    const ref = db.collection('music')
                  .doc(id)
                  
    const doc = await ref.get()
    
    if(doc.exists) {
      resolve(helpers.transformItem(doc))
    }
    else
      reject()
  })
}

export const loadYoutubeSong = (songName) => {
  return new Promise(async (resolve, reject) => {
    var url = 'https://www.googleapis.com/youtube/v3/search';
    var params = {
        part: 'snippet',
        key: 'AIzaSyAoBVHEwRzt1RnN6sLORUgoUjN22ED9MDc',
        q: songName
    };

    var url = new URL('https://www.googleapis.com/youtube/v3/search')

    url.search = new URLSearchParams(params).toString();

    fetch(url)
    .then(res => res.json())
    .then(res => {
      if(res.items)
        resolve(res.items[0].id.videoId)
      else
        resolve()
    })
  })
}

export const loadArtistSongs = (id) => {
  const db = firebase.firestore()

  return new Promise(async (resolve, reject) => {
    const ref = db.collection('music')
                  .where('artist.id', '==', id)
                  
    const docs = await ref.get()

    if(docs.empty) {
      resolve([])
    } else {
      let list = []

      docs.forEach(doc => {
        list.push(helpers.transformItem(doc))
      })

      resolve(list)
    }
  })
}