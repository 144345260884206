import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useOutsideAlerter } from './helpers';

export default function UserNav ({handleLogout}) {
  const [show, setShow] = useState(false)
  const [name, setName] = useState('loading')
  const user = useSelector(state => state.user)

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setShow(false)
  });

  useEffect(() => {
    if(user.loading)
      return setName('loading')
    if(!user.userData.id)
      return setName('Login / Register')
    if(user.username.loading)
      return setName('loading')
    if(user.username.set)
      return setName(`welcome ${user.username.username}`)
    
    return setName('username not set')
  }, [user])

  return (
    <div ref={wrapperRef}>
      <li className={"nav-item dropdown" + ( show ? ' show' : '' )}>
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={(event) => {
            event.preventDefault()
            setShow(!show)
          }}>
            { name }
        </a>  
        <div className={"dropdown-menu dropdown-menu-right" + ( show ? ' show' : '' )} aria-labelledby="navbarDropdown">
          { user.userData.id ? (
            <span>
              { (user.username.set) ?
                <Link to={"/u/" + user.username.username} onClick={() => setShow(false)} className="dropdown-item">User Profile</Link>
                : <span></span>
              }
              <a
                className="dropdown-item"
                href="/#"
                onClick={() => {setShow(false); handleLogout(false)}}
              >Logout</a>
            </span>
          ):(
            <span>
              <Link
                className="dropdown-item"
                to="/login"
                onClick={() => setShow(false)}
              >Login</Link>
              <Link
                className="dropdown-item"
                to="/register"
                onClick={() => setShow(false)}
                >Register</Link>
            </span>
          )}
        </div>
      </li>
    </div>
  )
}