import firebase from 'firebase'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { suggestedSongsCollection } from '../../constants'
import SuggestedSongsComp from './SuggestedSongs.presentation'

export default function SuggestedSongs () {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  
  useEffect(() => {
    firebase.firestore().collection(suggestedSongsCollection).onSnapshot(snap => {
      setLoading(false)

      let list = []
      snap.docs.forEach((doc) => {
        let artist = 'nope'
        if(doc.data().original && doc.data().original.artist)
          artist = doc.data().original.artist
        let song = 'nope'
        if(doc.data().original && doc.data().original.song)
          song = doc.data().original.song
        let date = null
        if(doc.data().created && doc.data().created.toDate) {
          date = moment(doc.data().created.toDate());
        }

        list.push({
          action: doc.data().action,
          artist,
          date: date,
          id: doc.id,
          song,
        })
      })

      list = list.sort((a, b) => {
        if(a.date > b.date)
          return -1
        return 1
      })

      setItems(list)
    })
  }, [])

  return (
    <SuggestedSongsComp
      items={items}
      loading={loading}
    />
  )
}