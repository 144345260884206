import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import FeedMusicComp from './FeedMusicComp'
import * as helpers from '../../helpers'
import FeedLoading from './FeedLoading'

export default function FeedMusic () {
  const [music, setMusic] = useState([])
  const [show, setShow] = useState(15)

  useEffect(() => {
    let subscriber = loadMusic()

    return () => {
      subscriber()
    }
  }, [])

  const loadMusic = () => {
    let musicRef = firebase.firestore()
                              .collection('music')
                              .orderBy('created', 'desc')

    return musicRef.onSnapshot(snap => {
      let list = []
      
      snap.docs.map(res => {
        list.push(helpers.transformItem(res))
      })

      setMusic(list)
    })
  }

  const showMore = (e) => {
    e.preventDefault()
    if(music.length > show + 15)
      setShow(show + 15)
    else
      setShow(music.length)
  }

  if(!music.length)
    return <FeedLoading />

  const partOfFeed = music.slice(0, show)

  return (
    <div className="Feed">
      <FeedMusicComp
        music={partOfFeed}
        playing={null}
        setPlaying={() => {}}
      />

      <hr />

      showing: { show } of { music.length }
      { show < music.length ? (
        <div className="text-center">
          <a href="#" className="btn btn-primary btn-block" onClick={e => showMore(e)}>show more</a>
        </div>
      ):(<></>)}
    </div>
  )
}