import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './SuggestSongButton.scss'

export default function SuggestSongButton () {
  return (
    <div class="SuggestSongButton d-flex justify-content-end">
      <Link to={'/add-song'} className="btn btn-danger add-song-btn">add song</Link>
    </div>
  )
}