import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation, useRouteMatch } from 'react-router';
import SongModal from './components/SongModal/SongModal';
import { startPlayer } from './features/player/playerSlice';
import * as FirebaseActions from './FirebaseActions'

export default function SongModalWrapper () {
  const dispatch = useDispatch()

  const [songId, setSongId] = useState(null)
  const [fullscreen, setFullscreen] = useState(null)
  const match = useRouteMatch("/song/:id");
  const player = useSelector(state => state.player)
  const [ song, setSong ] = useState(null)
  const [ yid, setYid ] = useState(null)
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    if(match)
      setFullscreen(true)
    else
      setFullscreen(false)

    let id = null
    if(match)
      id = match.params.id
    else if(player.id)
      id = player.id

    setSongId(id)
  }, [player, match])
  
  useEffect(() => {
    if(songId) {
      setYid(null)
      setLoading(true)

      FirebaseActions.loadSong(songId)
      .then(async song => {
        setSong(song)
        let yid = song.yid ?? await FirebaseActions.loadYoutubeSong(song.name)
        setYid(yid)
        setLoading(false)
      })

      dispatch(startPlayer(songId))
    }
  }, [songId])

  useEffect(() => {
    if(fullscreen) {
      let body = document.getElementsByTagName('body')[0]
      body.classList.add('modal-open')
    } else {
      let body = document.getElementsByTagName('body')[0]
      body.classList.remove('modal-open')
    }
  }, [fullscreen])

  return (
    <div>
      {songId ?
        <SongModal
          fullscreen={fullscreen}
          loading={loading}
          song={song}
          yid={yid}
        /> : undefined}
    </div>
  )
}