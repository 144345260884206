import React, { useEffect, useState } from 'react'
import './EditProfile.scss'
import * as FirebaseActions from '../../FirebaseActions'
import { useSelector } from 'react-redux'
import firebase from 'firebase'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router'

const EditProfile = () => {
  const history = useHistory()

  const [ loading, setLoading ] = useState(true)
  const [ user, setUser ] = useState({
    username: '',
    description: ''
  })
  const username = useSelector(state => state.user.username)
  const [ profilePic, setProfilePic ] = useState({
    loading: false,
    src: '',
  })

  useEffect(() => {
    if(username.loading)
      return
    if(!username.set){
      alert('username not set :(')
      return
    }
    loadCurrentUser()
  }, [username])
  
  const loadCurrentUser = async () => {
    FirebaseActions.loadProfile(username.username)
    .then(res => {
      setLoading(false)
      const newUser = res
      newUser.username = username.username

      setUser(newUser)
      if(newUser.profilePic) {
        setProfilePic({
          loading: false,
          src: newUser.profilePic
        })
      }
    })
    .catch(err => console.log(err))
  }

  const handleChange = (name, value) => {
    setUser({...user,
      [name]: value
    })
  }

  const uploadFile = (ref) => {
    setProfilePic({
      src: null,
      loading: true,
    })
    console.log(ref.target.files[0]);
    // return
    const file = ref.target.files[0]
    const storageRef = firebase.storage().ref()
    // Create the file metadata
    var metadata = {
      contentType: 'image/jpeg'
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef.child('profiles/' + username.username + '/' + file.name).put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      }, 
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log(profilePic);
          console.log('File available at', downloadURL);
          setProfilePic({
            loading: false,
            src: downloadURL,
          })
        });
      }
    );
  }

  const save = () => {
    user.profilePic = profilePic.src

    FirebaseActions.updateProfile(user)
    .then(() => {
      history.push("/u/" + username.username);
    })
    .catch(() => {
      toast.error('permission denied')
    })
  }

  const deletePic = () => {
    setProfilePic({
      loading: false,
      src: null
    })
  }
  if(loading)
    return (
      <div>loading</div>
    )
  return (
    <div className="edit-profile">
      <h3>edit profile</h3>
      <hr />
      <div className="row">
        <div className="col-sm-8">
          { profilePic.changed ? (
            <div className="alert alert-success">
              don't forget to save
            </div>
          ) : (
            <div></div>
          ) }
          <div className="form-group">
            <label>username</label>
            <input
              type="text"
              value={user.username}
              className="form-control"
              disabled={true}
            />
            <small>Locked</small>
          </div>
          <div className="form-group">
            <label>profile pic (optional)</label>
            <div className="row">
              <div className="col-sm-4">
                <div className="profile-pic">
                  { profilePic.src ? (
                    <img className="w-100" src={profilePic.src}/>
                  ):(
                    <div>no pic</div>
                  ) }
                </div>
              </div>
              <div className="col-sm-8">
                { profilePic.src ? (
                  <button className="btn btn-danger" onClick={deletePic}>delete</button>
                ):(
                  <input
                    type="file"
                    onChange={uploadFile}
                  />
                ) }
              </div>
            </div>
            <small>Paste a link in this field. Leave empty for robot placeholder.</small>
          </div>
          <div className="form-group">
            <label>short bio (optional)</label>
            <textarea
              onChange={ ref => handleChange('description', ref.target.value)}
              value={user.description}
              className="form-control"
              rows={5}
            />
            <small>Write a short description about yourself!</small>
          </div>
          <button className="btn btn-primary btn-block" onClick={save}>save changes</button>
        </div>
        <div className="col-sm-4 d-none d-lg-block preview">
          { profilePic.loading ? (
            <div>uploading pic</div>
          ):(
            <img className="w-100" src={profilePic.src}/>
          )}
          <hr />
          <h3>
            {user.username}
          </h3>
          <div className="description">
            {user.description}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditProfile