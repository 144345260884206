import React, { useEffect, useState } from 'react'
import FeedComponent from './FeedComponent'
import firebase from 'firebase'
import FeedLoading from '../FeedMusic/FeedLoading'

export default function Feed () {
  const db = firebase.firestore()
  const [activity, setActivity] = useState([])

  useEffect(() => {
    const snapshot = db.collection('feed')
                        .where('community', '==', 'rapbase')
                        .orderBy('created_at', 'desc')
                        .onSnapshot(snap => {
      let list = []
      snap.docs.forEach(doc => {
        list.push(doc.data())
      })
      setActivity(list)
    })

    return () => {
      snapshot()
    }
  }, [])

  if(!activity.length)
    return <FeedLoading />

  return (
    <div>
      <FeedComponent activity={activity}/>
    </div>
  )
}