import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// 
import './Users.scss';

const Users = () => {
  const loading = useSelector(state => state.usernames.loading)
  const users = useSelector(state => state.usernames.usernames)

  return (
    <div>
      <h3>Users</h3>
      <div className="row">
        { loading ? (
          <span>loading</span>
        ):(
          users.map( user =>
            <Link to={"/u/" + user.username} className="col-sm-3" key={user.id}>
              { user.profilePic ? 
                <div className="picPlaceholder" style={{backgroundImage: 'url('+user.profilePic+')'}}></div>
                :<img src={"https://avatars.dicebear.com/4.5/api/bottts/" + user.username + ".svg"}/>
              }
              <div className="usersName">{ user.username }</div>
            </Link>
          )
        )}
      </div>
    </div>
  )
}

export default Users