import React, { useEffect } from 'react';
import {
  setNav,
  selectNav,
} from '../features/app/appSlice';
import {
  setUser,
  setUsername,
  selectUser,
  setLikes,
} from "../features/user/userSlice";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as firebase from "firebase";
// 
import './Header.scss'
import { toast } from 'react-toastify';
import * as FirebaseActions from '../FirebaseActions'
import UserNav from './UserNav';
import { likesCollection } from '../constants';

window.loadLikes = null

export default function Header() {
  const history = useHistory();

  const nav = useSelector(selectNav);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(setUser({
          id: user.uid,
          email: user.email
        }))

        FirebaseActions.loadUsername(firebase, user.uid)
        .then(res => {
          dispatch(setUsername(res))
          if(!res.set){
            history.push("/set-username");
          }
        })
      } else {
        dispatch(setUser({
          id: null,
          email: null
        }))
      }
    });
  }, [])

  useEffect(() => {
    dispatch(setLikes([]))

    if(window.loadLikes) {
      window.loadLikes()
    }

    if(user.userData.id) {
      let likesRef = firebase.firestore()
                                .collection(likesCollection)
                                .where('user.username', '==', user.username.username)

      window.loadLikes = likesRef.onSnapshot(res => {
        let likes = []
        res.docs.map(like => {
          likes.push({
            songId: like.data().songId
          })
        })
        dispatch(setLikes(likes))
      })
    }
  }, [user.username.username])
  
  const handleLogout = () => {
    firebase.auth().signOut()

    toast.success('logged out, see you soon!')
  }

  return (
    <nav className="main-header navbar fixed-top navbar-expand navbar-dark" style={{marginLeft: 0}}>
      <ul className="navbar-nav">
        <li className="nav-item d-inline-block d-md-none">
          <a href="/#" className="nav-link" onClick={e => {e.preventDefault(); dispatch(setNav(!nav))}}>
            <i className="fas fa-bars" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
      <ul className="navbar-nav">
        <li className="nav-item active">
          <Link className="nav-link navbar-brand" to="/">Home</Link>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <UserNav handleLogout={handleLogout}/>
      </ul>
    </nav>
  )
}
