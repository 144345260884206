import React from 'react'
import './MultiStep.scss'

export default function MultiStep ({config, step = 0}) {
  const liClass = (index) => {
    if(index + 1 < step)
      return 'checked'
    if(index + 1 == step)
      return 'active'
    return ''
  }
  return (
    <div className="multistep">
      <ul>
        { config && config.map((item, index) => {
          return (
            <li className={liClass(index)}>
              <i className={item.icon}></i>
              <div>{item.text}</div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
