import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.scss'

export default function Breadcrumbs ({breadcrumbs}) {
  return (
    <div className="breadcrumbs">
      <ul>
        <li><i class="fas fa-home"></i></li>
        {breadcrumbs && breadcrumbs.map(item =>
          <>
            <li><i class="fas fa-chevron-right"></i></li>
            <li>
              {item.href ?
              <Link to={item.href}>{item.text}</Link>
              :<span>{item.text}</span>}
            </li>
          </>
        )}
      </ul>
    </div>
  )
}
