import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import './Artist.scss'
import ArtistInformationPanel from "./ArtistInformationPanel";
import Loading from "../../components/Loading";
import Breadcrumbs from '../../components/Breadcrumbs'
import { extractFirstLetter } from "../../components/helpers";
import coverPic from '../../images/cover-pic.png'
import * as FirebaseActions from '../../FirebaseActions'
import { FeedComponent } from "../../components/Feed/Feed";

const Artist = ({}) => {
  const params = useParams()
  const db = firebase.firestore()
  const [songs, setSongs] = useState([])
  const [ artist, setArtist ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ breadcrumbs, setBreadcrumbs ] = useState([])

  const loadArtist = async () => {
    const ref = await db.collection('artists').doc(params.artist).get()
    if(ref.exists) {
      let bornDate = new Date(ref.data().born.toDate())

      setArtist({
        born: bornDate,
        cover_pic: ref.data().cover_pic,
        description: ref.data().description,
        id: ref.id,
        name: ref.data().artist,
        occupation: ref.data().occupation,
        other_names: ref.data().other_names,
        pic: ref.data().pic,
        years_active: ref.data().years_active,
      })

      setBreadcrumbs([{
        text: 'Artists',
        href: '/artists'
      },{
        text: extractFirstLetter(ref.data().artist)
      },{
        text: ref.data().artist
      }])

      const res = await FirebaseActions.loadArtistSongs(ref.id)
      setSongs(res)
    }
    setLoading(false)
  }

  useEffect(() => {
    loadArtist()
  }, [])

  return (
    <div className="page-artist">
      <Loading loading={loading}/>

      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <div class="cover">
        <img src={artist.cover_pic ? artist.cover_pic : coverPic} />
      </div>
      <div className="row">
        <ArtistInformationPanel artist={artist}/>
        
        <div className="col-sm-8 order-sm-1">
          {artist.description}
          <div>
            {songs && songs.length ?
              <FeedComponent music={songs} playing={false} setPlaying={() => {}} PrintDateIfNeeded={() => <></>}/>
            : <div>no songs</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Artist