// https://github.com/lukehaas/css-loaders
import React, { useEffect, useState } from 'react'
import './Loading.scss'

export default function Loading ({loading}) {
  const [ localLoading, setLocalLoading ] = useState(true)
  const [ hide, setHide ] = useState(false)

  useEffect(() => {
    if(loading) {
      setLocalLoading(true)
    } else {
      setLocalLoading(false)
      setTimeout(() => {
        setHide(true)
      }, 500);
    }
  }, [loading])

  function containerClass () {
    let classes = "css-loading"

    if(!localLoading)
      classes += " opacity-0"
    if(hide)
      classes += " d-none"

    return classes
  }
  function animationClass () {
    let classes = ""

    if(!localLoading)
      classes += " animate__bounceOut"

    return classes
  }
  return (
    <div class={containerClass()}>
      <div className={animationClass()}>
        <div class="loader">
          Loading...
        </div>
      </div>
    </div>
  )
}